import React from 'react'; 
import './login.css'
import Navbar from '../nav/Navbar';
import Footer from '../nav/Footer';
import {LoginDialogue, ManageAccount} from './fn_login';
import CONFIG from '../../config.json';


export default function Login({page}){
    return (
        <div className='height-stretch'>

            <img src='/images/img9.jpg' className='background-image-fixed' />

            <Navbar />
            <div className='navbar-space' />

            <div className='height-grow login'>
                
                <div className='space100' />
                <div className='content'>
                    {page === 'login' && <LoginDialogue apiUrl={CONFIG.api} webId={CONFIG.web} redirect={"/home"} />}
                    {page === 'account' && <ManageAccount apiUrl={CONFIG.api} webId={CONFIG.web} redirect={"/home"} />}
                </div>
                
            </div>


            <Footer />
        </div>
    );
}
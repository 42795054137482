import React from 'react';

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }


export default function useVideoSize(){
  const [videoDim, setVideoDim] = React.useState(window.innerWidth > 1000 ? {width:500, height:Math.floor(500*(110/400))} : {width:300, height:Math.floor(300*(110/400))});

  React.useEffect(() => {
    const handleResize = debounce(() => setVideoDim(window.innerWidth > 1000 ? {width:500, height:Math.floor(500*(110/400))} : {width:300, height:Math.floor(300*(110/400))}), 500)

    window.addEventListener('resize', handleResize)
    return _ => { window.removeEventListener('resize', handleResize)}
  })    
  return videoDim;
}


import React,{useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './yoga.css';
import useVideoSize from './useVideoSize';
 
export default function Yoga( {data} ) {
    /*
    const [data,setData] = useState({
         rounds: [{rid:1,b:30, h:30},{rid:2,b:30, h:30},{rid:3,b:30, h:30}]
         ,language:'se'
    });*/

    // VIDEO SUPPORT - START

    let navigate = useNavigate();
    const videoSize = useVideoSize(); // used for setting video size
    const [play,setPlay] = useState(-1); // used for play/pause
    const vidRef = useRef(); // used for linking dom for video control
    const [roundId,setRoundId] = useState(0);
    const [info,setInfo] = useState('');
    
    useEffect(() => parse_exercise(), [data]);

    const parse_exercise = () => {
        const m = `./media/${data.language}`;
        const nfo = `Övning med ${data.rounds.length} rundor`;
        let ymedia = [ `${m}/start.mp4`]
        let ymeta = [`0;${nfo}`]
        data.rounds.forEach(x => {
            ymedia.push(`${m}/r${x.rid}.mp4`);
            ymeta.push(`${x.rid};Redo`);
            ymedia.push(`${m}/b${x.b}.mp4`);
            ymeta.push(`${x.rid};Andas`);
            ymedia.push(`${m}/h${x.h}.mp4`);
            ymeta.push(`${x.rid};Andningsuppehåll`);
            ymedia.push(`${m}/rec.mp4`);
            ymeta.push(`${x.rid};Återhämning`);
        });
        ymedia.push(`${m}/stop.mp4`);
        ymeta.push('0;Andas normalt igen');
        localStorage.setItem('ymedia', ymedia.join(','));
        localStorage.setItem('ymeta', ymeta.join(','));
        setInfo(nfo);
    }

    const TogglePlay = () => {
        if(play === -1)
        {
            handleNext();
            setPlay(1);
        }
        else{
            const p = play === -1 ? 1 : (play === 1 ? 0 : 1);
            if(p === 1)
            {
                vidRef.current.play();
            }
            else
            {
                vidRef.current.pause();
            }
            setPlay(p);
        }
    }

    const FormatTime = (seconds, alpha=true) => {
        const m = Math.floor(seconds/60);
        const s = seconds % 60;
        return (m > 9 || !alpha ? m.toString() : `0${m}`) +':' + (s > 9 ? s.toString() : `0${s}`);
    }

    const estimateTime = (rnds) => {
        // calculate estimated time
        var tm = rnds.length > 0 ? 24 : 0; // initiate with passive time: start/stop
        rnds.forEach(x => {
            tm += x.b * 3.2 + x.h; // +3s per breath + holdcount  
            tm += 3 + 20; // 3s round callout + 17 recovery breath
        });
        return tm;
    }


    const getVid = () => {
        let video = '';
        let ymedia = localStorage.getItem("ymedia").split(',');
        if (ymedia[0].length > 0)
        {
            video = ymedia.shift();
            localStorage.setItem('ymedia', ymedia.join(','));
        }
        else
            localStorage.setItem('ymedia','');
            
        let ymeta = localStorage.getItem("ymeta").split(',');
        if (ymeta[0].length > 0)
        {
            const nfo = (ymeta.shift()).split(';');
            localStorage.setItem('ymeta',ymeta.join(','));
            const rid = parseInt(nfo[0]);
            setRoundId(rid);
            setInfo(nfo.length === 2 ? nfo[1] : 'no info');
        }
        else
            localStorage.setItem('ymeta','');

        return video;
    }

    
    const handleNext = () => {
        let video = getVid();
        if(video.length > 0)
        {
            vidRef.current.muted=true;
            vidRef.current.src = video;
            vidRef.current.play();
            vidRef.current.muted=false;
        }
        else{
            setPlay(2);
        }
    }


    
    return (
        <div className='yoga2'>
            <img src='/images/view1.jpg' className='bg-img hide'  onLoad={(a) => a.currentTarget.className = 'bg-img show' } />

            

            <div className='grid'>
                <div className='frame'>

                    <div className='gauge'>
                        <div className='video'>
                            <video ref={vidRef} playsInline autoPlay muted onEnded={() => handleNext()} type="video/mp4" width={videoSize.width} height={videoSize.height} />
                        </div>

                        <div className='info'>
                            <h2>{info}</h2>
                        </div>

                        <div className='buttons'>
                            {play !== 2 && 
                                <button onClick={() => TogglePlay()}>
                                    {play === -1 ? 'START' : (play === 0 ? 'RESUME' : 'PAUSE')}
                                </button>
                            }
                            <button onClick={() => navigate("/")}>QUIT</button>
                        </div>


                    </div>

                </div>


                <div className='frame shaded'>
                <div className='overview'>
                        
                        <ul>
                            {
                                data.rounds.map((item,index) => 
                                <li key={index} className={ roundId === item.rid ? 'active' : '' }>
                                    {data.language === 'se' ? 'Runda ' : 'Round '} 
                                    {item.rid}: {item.b} + {FormatTime(item.h, false)}
                                </li>)
                            }
                        </ul>
                        <p>{data.language === 'se' ? 'Uppskattad tid' : 'Estimated time'} {data.time ? FormatTime(data.time) : FormatTime(estimateTime(data.rounds))} 
                        </p>
                    </div>

                </div>


            </div>

        </div>
    );
}

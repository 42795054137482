import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../nav/Navbar';
import './home.css'

import {MakePath} from '../editor/functions';

export default function Home(){
    return(
        <div className='home'>
            <Navbar opacity={true} />

            <div className='welcome-layout1 top'>
                <div className='animation' style={{backgroundImage:`url(/images/img15.jpg)`}} />
            </div>
            
            <div className='welcome-layout1 bottom'>

                <div className='boxes width-fit'>
                    <Link to='/method' className='box'>
                        <img src='/images/small1.png' />
                        <h3>AVSLAPPNING</h3>
                        Prova andas bort din stress.<br />
                        Läs mer här om hur det går till.                         
                    </Link>

                    <Link to='/exercise' className='box'>
                        <img src='/images/small2.png' />
                        <h3>NYBÖRJARE?</h3>
                        Prova på genom att välja övningar<br />från en redan färdig lista. 
                    </Link>

                    <Link to='/exercise' className='box'>
                        <img src='/images/small3.png' />
                        <h3>AVANCERAD?</h3>
                        Bygg dina egna sessioner.<br />
                        Välj uppandning och nedräkning.
                    </Link>
                </div>

            </div>
        
        </div>
    );
}

import React,{useEffect} from 'react';
import './support.css';
import Navbar from '../nav/Navbar';
import Footer from '../nav/Footer';

export default function Support(){
    useEffect(() => window.scrollTo(0,0) ,[]);

    return(
        <div className='support height-stretch'>

            <Navbar opacity={true} />
            <div className='navbar-space' />

            <img src='/images/img9.jpg' className='background-image-fixed' />

            <div className='height-grow page-wrapper'>
                <div className='text-content notestyle'>
                    <div className='text'>
                        <p>
                        Gillar du sidan och vill att den ska finnas kvar?<br />
                        Stöd gärna med en slant. 
                        </p>

                        <div className='developer centralize'>
                            <div>
                                <h2>UTVECKLARE</h2>
                                <span>Peter Astner</span> <br />
                                Swish: 070 71 49499
                            </div>
                                
                            
                        </div>

                    </div>
                    
                </div>
            </div>

            <Footer />
        </div>
    );
}

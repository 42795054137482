import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './method.css'
import icon_edit from '../_icons/edit.png';
import Navbar from '../nav/Navbar';
import Footer from '../nav/Footer';
import CONFIG from '../../config.json';
import {Credentials} from '../login/fn_login';

export default function Method(){
    const navigate = useNavigate();
    const [content,setContent] = useState([]);
    const [image,setImage] = useState('/images/img1.jpg');
    const [imageIndex,setImageIndex] = useState(1);
    
    useEffect(() => {
        window.scrollTo(0,0);
        getContent();
    },[]);

    const getContent = async () => {
        const response = await fetch('/db/method.json', {cache:'no-cache'});
        if (response.ok)
        {
            var data = await response.json();
            setContent(data);
        }
    }

    return (
        <div className='method height-stretch'>

            <Navbar />
            <div className='navbar-space' />

            <div className='width-fit page-wrapper height-grow'>

                {content.map((item,index) => 
                    <div className={index !== 0 ? 'text-content notestyle separate' : 'text-content notestyle'} key={index}>
                        {item.image && <img src={item.image} className={index % 2 === 0 ? 'right mobile' : 'left mobile'} />}
                        <div className='text'>
                            {index === 0 ? <>
                                <h1>{item.title}</h1>
                                {Credentials.role() === 'admin' && <button className='edit-icon' onClick={() => navigate("/method-edit")} style={{backgroundImage:`url('${icon_edit}')`}} />}
                            </> : <h2>{item.title}</h2>}
                            {item.body.split('\n').map((txt,ix) => <p key={ix}>{txt}</p>)}
                        </div>
                    </div>
                )}
               

            </div>

            <Footer />

        </div>
    );
}

import { Credentials } from './fn_login';
// Private

const apiGetLocation = async () => {
    let request = await fetch("https://geolocation-db.com/json", {method:'get'});
    if(request.ok)
    {
      try{
          let data = await request.json();
          return {'ip':data.IPv4, 'country':data.country_code, 'state':data.state, 'city':data.city}
      }
      catch(e) { console.log("warning: could not get IP/location"); }
    }
    return {ip:'unknown'};
}

const apiLog = async (__web, __api, __jsonString) => {
    let request = await fetch(__api + `/${__web}/log`, {method:'post', body:__jsonString, headers:{"Content-Type":"application/json"}});
    if (!request.ok){
        console.log('Could not send log');
    }
        
}

const create_jsonString_event = (_ip, _user, _event, _text) => { 
    return JSON.stringify({'ip':_ip, 'user':_user, 'event':_event, 'text':_text});
}


const check_refresh_location = async () => {
    if(!ClientLocation.isVerified())
    {
        let d = await apiGetLocation();
        new ClientLocation(d);
    }
}

  // Public
export const log_location = async (_web, _api) => {
    await check_refresh_location();
    let location = `${ClientLocation.country}/${ClientLocation.state}/${ClientLocation.city}`;
    let location_payload = create_jsonString_event(ClientLocation.ip, Credentials.user(), 'init', location);
    await apiLog(_web, _api, location_payload);
}

export const log_credentials = async (_web, _api) => {
    await check_refresh_location();
    let credential_payload = create_jsonString_event(ClientLocation.ip, Credentials.user(), 'login', 'user-login');
    await apiLog(_web, _api, credential_payload);
} 

export const log_action = async (_web, _api, _eventid, _text) => {
    await check_refresh_location();
    let payload = create_jsonString_event(ClientLocation.ip, Credentials.user(), _eventid, _text);
    await apiLog(_web, _api, payload);
}

// Class
export class ClientLocation{
    static keys = ['ip','country', 'city','state'];
    
    constructor(json){
        ClientLocation.keys.forEach(k => {
            if(Object.keys(json).includes(k)) localStorage.setItem(k, json[k]);
        });
    }

    static ip = localStorage.getItem('ip',null);
    static country = localStorage.getItem('country',null);
    static city = localStorage.getItem('city',null);
    static state = localStorage.getItem('state',null);

    static isVerified = () => { return ClientLocation.ip !== null }

    static destroy = () => ClientLocation.keys.forEach(k => localStorage.removeItem(k));
}



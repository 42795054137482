import React, { useEffect, useState } from 'react';
import './exercise.css';
import Navbar from '../nav/Navbar';
import Footer from '../nav/Footer';
import Yoga2 from '../yoga2/yoga';
import Loader from '../loader/loader';
import { Credentials } from '../login/fn_login';
import { log_action } from '../login/fn_log';
 
export default function Exercise({apiUrl, dbDir, web}){
    const [viewId,setViewId] = useState(0);
    const [runSession,setRunSession] = useState({});
    const [exerciseList, setExerciseList] = useState([]);
    const [editId,setEditId] = useState(-1);
    
    const [mainFirstRender,setMainFirstRender] = useState(false);
    useEffect(() => setMainFirstRender(true),[])
    useEffect(() => {
        if(mainFirstRender)
        {
            getData();
            setMainFirstRender(false);
        }
            
    },[mainFirstRender]);


    const getData = async () => {
        // first get training set
        const lst = [];
        const tResponse = await fetch(dbDir + "/exercise.json", {cache:'no-cache'})
        .then(promise => promise.json())
        .then(data => data.forEach(d => lst.push(d)))
        .catch(e => console.log("Error: " + e));
        // then try get user data
        if(Credentials.isValid())
        {
            const uResponse = await fetch(dbDir + `/${Credentials.id()}.json`, {cache:'no-cache'});
            if(uResponse.ok)
            {
                try{
                    var uData = await uResponse.json();
                    uData.forEach(d => {
                        d["category"] = 'user';
                        lst.push(d);
                    });
                }
                catch(e) {console.log("error on parsing UserData: "+ e)}
            }
            else
                console.log("no user data found");
        }
        setExerciseList(lst);
    }

    const resetMainView = () => {
        setViewId(0);
        setEditId(-1);
        getData();
    }

    const secondsToTime = (s) => {const sec = s % 60;const min = Math.floor(s/60);return min.toString() + ':' + (sec.toString().length == 1 ? `0${sec}` : sec.toString());}

    const exerciseSelect = (id) => {
        var selectedConfiguration = exerciseList.find(a => a.id === id);
        if(selectedConfiguration  !== undefined)
        {
            setRunSession(selectedConfiguration);
            let ri = [];
            selectedConfiguration.rounds.forEach(r => ri.push(`(id:${r.rid},b:${r.b},h:${r.h})`));
            log_action(web, apiUrl, 'yoga',`rounds:${selectedConfiguration.rounds.length}, config: ${ri.join('/')}`);
            setViewId(100);
        }
    }

    const estimateTime = (rnds) => {
        // calculate estimated time
        var tm = rnds.length > 0 ? 24 : 0; // initiate with passive time: start/stop
        rnds.forEach(x => {
            tm += x.b * 3.2 + x.h; // +3s per breath + holdcount  
            tm += 3 + 20; // 3s round callout + 17 recovery breath
        });
        return tm;
    }

    // VIEWS
    const MenuRoot = () => {
        return (<>
                    <button className='exercise-box' onClick={() => setViewId(1)}>
                        <span className='header'>NYBÖRJARE</span>
                        <span className='text'>Några enklare övningar med guidning</span>
                    </button>
                    
                    <button className='exercise-box' onClick={() => setViewId(2)}>
                        <span className='header'>AVANCERADE</span>
                        <span className='text'>Övningar för dig som vet hur det fungerar</span>
                    </button>

                    {exerciseList.length > 6 &&
                    <button className='exercise-box' onClick={() => setViewId(3)}>
                        <span className='header'>SPARADE</span>
                        <span className='text'>Välj bland dina sparade övningar</span>
                    </button>}

                    <button className='exercise-box' onClick={() => {setEditId(-1);setViewId(4);}}>
                        <span className='header'>SKAPA NYTT</span>
                        <span className='text'>Skapa din egen övning. Skaffa konto om du vill spara den.</span>
                    </button>
        </>);
    }

    const Predefined = ({category}) => {
        const editClick = (e, id) => {
            setEditId(id);
            setViewId(4);
            e.stopPropagation();
        }

        return(
            <>
                <button className='back-button' onClick={() => setViewId(0)}>
                    &#8678; Tillbaka / ({category === 'beginner' ? 'Nybörjare' : (category === 'advanced' ? 'Avancerad' : 'Sparade')})
                </button>

                {exerciseList.filter(y => y.category === category).sort((a,b) => a.id-b.id).map((item,index) => 
                    <div className='exercise-box' key={index} onClick={() => exerciseSelect(item.id)}>
                        <div className={item.category === 'user' ? 'header separate' : 'header'}>
                            <span>{item.title}</span>
                            {item.category=== 'user' && <button onClick={(e) => editClick(e, item.id)} className='edit-button'>Redigera</button>}
                        </div>
                        <span className='text'>
                            {item.text}
                            <ul>
                            {item.rounds.sort((a,b)=> a.rid-b.rid).map((round,rix) => 
                                <li key={rix}><span>Runda {round.rid}: </span>{round.b} + {secondsToTime(round.h)} adningsstopp</li>
                            )}
                            </ul>
                        </span>    
                        <span style={{fontWeight:600}}>Cirka total tid: {item.time ? secondsToTime(item.time) : secondsToTime(estimateTime(item.rounds))}</span>
                    </div>
                )}
            </>
        );
    }


    const Customize = () => {
        const [load,setLoad] = useState(false);
        const [roundId,setRoundId] = useState(-1);
        const [meta, setMeta] = useState({ "title":"","text":"", "language":"se", "audio":"monks", "time":0});
        const [rounds,setRounds] = useState([]);

        // first arrive stuff
        const [customFirstRender,setCustomFirstRender] = useState(false);
        useEffect(() => setCustomFirstRender(true),[]);
        useEffect(() => {
            if(customFirstRender)
            {
                // set object to edit
                if(editId !== -1)
                {
                    const r = [];
                    const m = {};
                    const itemToEdit = exerciseList.find(x => x.id === editId);
                    // make sure to copy item
                    Object.keys(itemToEdit).forEach(key => {
                        // copy exercise
                        if(key === 'rounds')
                            itemToEdit[key].forEach(ex => r.push({...ex}));
                        if(!['rounds'].includes(key)) m[key] = itemToEdit[key];
                    });
                    setMeta(m);
                    setRounds(r);
                }
                if(roundId !== -1) setRoundId(-1);
                setCustomFirstRender(false);
            }
        },[customFirstRender]);

        const compileSession = () => {
            var thisSession = {...meta};
            thisSession["rounds"] = [];
            for(var x=0;x<rounds.length;x++)
                thisSession["rounds"].push({...rounds[x]});
            if(thisSession.id === undefined || thisSession.id === null) thisSession["id"] = new Date().getTime();
            if(thisSession.title.length === 0) thisSession.title = "Övning";
            return thisSession;
        }

        const addRound = () => {
            const getRound = (fromRound = null) => fromRound !== null ? 
            {rid:(fromRound.rid +1), b:fromRound.b, h:(fromRound.h + 30)} 
            : {rid:1, b:30, h:90};
            var newRound = getRound(rounds.length > 0 ? {...rounds[rounds.length-1]} : null);
            // add to existing array
            setRounds(e => {
                const a = [];
                for(var x=0;x<e.length;x++)
                    a.push({...e[x]});
                a.push(newRound);
                var tm = estimateTime(a);
                handleMeta('time', tm);
                return a;
            });
            setRoundId(newRound.rid);
        }

        const removeRound = (id) => {
            const newRounds = [];
            for(var x=0;x<rounds.length;x++)
            {
                if(rounds[x].rid < id)
                    newRounds.push({...rounds[x]});
                if(rounds[x].rid > id)
                {
                    var r = {...rounds[x]};
                    r.rid = r.rid-1;
                    newRounds.push(r);
                }
            }
            var tm = estimateTime(newRounds);
            handleMeta('time', tm);
            setRounds(newRounds);
        }

        const handleMeta = (key, value) => {
            setMeta(a => {
                var m = {...a};
                m[key] = value;
                return m;
            });
        }
        
        const handleRound = (rid, key, value) => {
            const index = rounds.findIndex(x => x.rid === rid);
            var newValue = undefined;
            // handle breathhold
            if(['minutes','seconds'].includes(key))
            {
                newValue = rounds[index].h;
                const cMin = getMinutes(newValue);
                const cSec = getSeconds(newValue);
                if(key === 'minutes') newValue = value * 60 + cSec;
                if(key === 'seconds') newValue = cMin * 60 + parseInt(value);
                if(newValue <= 15) newValue = undefined;
            }
            else
                newValue = rounds[index][key] === value ? undefined : value;

            if(newValue !== undefined)
            {
                var roundsCopy = [...rounds];
                var roundCopy = {...rounds[index]};
                roundCopy[['seconds','minutes'].includes(key) ? 'h' : key] = newValue;
                roundsCopy[index] = roundCopy;

                var tm = estimateTime(roundsCopy);
                setRounds(roundsCopy);
                handleMeta("time", tm);
            }
        
        }

        const destroySession = async () =>
        {
            if(Credentials.isValid())
            {
                setLoad(true);
                const fd = new FormData();
                fd.append('token', Credentials.token());
                fd.append('dbPath', `${dbDir}/${Credentials.id()}.json`);
                const resp = await fetch(apiUrl + `/${web}/exercise/${meta.id}`, {body:fd, method:'delete'});
                if(!resp.ok) 
                {
                    setLoad(false);
                    alert('Något blev fel.\nKunde inte radera övningen.');
                }
                else 
                {
                    setLoad(false);
                    resetMainView();
                }
            }
        }

        const saveSession = async () => {
            if(Credentials.isValid())
            {
                setLoad(true);
                const fd = new FormData();
                fd.append('token', Credentials.token());
                fd.append('dbPath', `${dbDir}/${Credentials.id()}.json`);
                fd.append('item', JSON.stringify(compileSession()));
                const resp = await fetch(apiUrl + `/${web}/exercise`, {body:fd, method:'post'});
                if(!resp.ok) 
                {
                    alert('Något blev fel.\nKunde inte spara övningen.');
                }
                else {
                    setLoad(false);
                    resetMainView();
                }
                setLoad(false);
            }
            else
                alert("Your access token has expired\nLogin again!");
        }

        const startSession = () => {
            setRunSession(compileSession());
            setViewId(100);
        } 

        const getMinutes = (seconds) => Math.floor(seconds / 60);
        const getSeconds = (seconds) => seconds % 60;
        const asAlpha = (num) => num.toString().length >=2 ? num.toString() : `0${num}`;

        return (
            <>
                {load && <Loader /> }

                <button className='back-button' onClick={()=> setViewId(0)}>&#8678; Tillbaka / (Skapa)</button>
                
                <div className='button-field'>
                    <button onClick={() => destroySession()} className={(meta.id && Credentials.id() !== null) ? 'button-toolbar delete start' : 'button-toolbar disabled start'} disabled={!meta.id}>RADERA</button>
                    <button onClick={() => saveSession()} className={Credentials.id() !== null && rounds.length > 0 ? 'button-toolbar save' : 'button-toolbar disabled'} disabled={Credentials.id() === null || rounds.length <= 0}>SPARA</button>
                    <button onClick={() => startSession()} className={rounds.length > 0 ? 'button-toolbar action' : 'button-toolbar disabled'} disabled={rounds.length <= 0}>STARTA</button>
                    <button className='button-toolbar action end' onClick={() => addRound()}>RUNDA+</button>
                </div>

                <br />

                <div className='item settings'>
                    <button className='header' onClick={() => setRoundId(a => a === 0 ? -1 : 0)}>
                        <div className='separate'>
                            Inställningar <span>{roundId === 0 ? '▲' : '▼'}</span>
                        </div>
                        <div className='header-extra'>{meta.title || 'Övning'}  - {secondsToTime(meta.time || 0)}</div>
                    </button>
                    <div className={roundId === 0 ? 'controls' : 'controls hide'}>
                        <input type="text" placeholder='Titel' value={meta.title || ''} onChange={(e) => handleMeta('title', e.target.value)} />
                        <input type="text" placeholder='Alt beskrivning' value={meta.text || ''} onChange={(e) => handleMeta('text', e.target.value)} />

                        <span>Theme</span>
                        <div className='button-field'>
                            <button disabled={true} onClick={() => handleMeta('language', 'en')} className={meta.language === 'en' ? 'form-button enabled':'form-button'}>Engelska</button>
                            <button disabled={true} onClick={() => handleMeta('language', 'se')} className={meta.language === 'se' ? 'form-button enabled':'form-button'}>Svenska</button>
                        </div>

                    </div>
                </div>


                {rounds.sort((a,b) => a.rid-b.rid).map((round,index) => 
                    <div className='item round' key={index}>
                    <button className='header separate' onClick={() => setRoundId(a => a === round.rid ? -1 : round.rid)}>
                        Runda {round.rid} - {round.b} + {asAlpha(getMinutes(round.h))}:{asAlpha(getSeconds(round.h))} 
                        <span>{round.rid === roundId ? '▲' : '▼'}</span>
                    </button>
                    <div className={round.rid === roundId ? 'controls' : 'controls hide'}>
                        <span className='subheader'>Uppandning</span>
                        <span>Andetag</span>
                        <div className='button-field'>
                            <button onClick={() => handleRound(round.rid, 'b',30)} className={round.b === 30 ? 'form-button enabled' : 'form-button'}>30</button>
                            <button onClick={() => handleRound(round.rid, 'b',35)} className={round.b === 35 ? 'form-button enabled' : 'form-button'}>35</button>
                            <button onClick={() => handleRound(round.rid, 'b',40)} className={round.b === 40 ? 'form-button enabled' : 'form-button'}>40</button>
                        </div>
                        
                        <br />
                        <span className='subheader'>Andningsstopp</span>
                        <div className='list-control'>
                            <div>
                                <span>Minuter</span>
                                <select size="4" value={getMinutes(round.h)} onChange={(e) => handleRound(round.rid, 'minutes', e.target.value)}>
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </select>
                            </div>

                            <div>
                                <span>Sekunder</span>
                                <select size="4" value={getSeconds(round.h)} onChange={(e) => handleRound(round.rid, 'seconds', e.target.value)}>
                                    <option>0</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                            </div>

                        </div>
                        <br />
                        <div className='button-field'>
                            <button onClick={() => removeRound(round.rid)} className='delete-button'>Ta bort</button>
                        </div>
                    </div>
                </div>                
                )}

            </>
        );
    }


    
    return viewId !== 100 ? 
        <>
        <div className='exercise height-stretch'>
            <Navbar />
            <div className='navbar-space' />

            <div className='height-grow width-fit'>

                <h1>ÖVNINGAR</h1>

                <div className='selections'>

                    {/* STATIC EXERCIESES */}
                    {viewId === 0 && <MenuRoot />}
                    {[1,2,3].includes(viewId) && <Predefined category={viewId === 1 ? 'beginner' : (viewId === 2 ? 'advanced' : 'user')} />}
                    {viewId === 4 && <Customize />}

                </div>

            </div>

            <Footer />
        </div>
        </> : <Yoga2 data={runSession} />
        
    ;
}


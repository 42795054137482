import React,{useState,useEffect} from 'react';
import {useNavigate,Link} from 'react-router-dom';
import './Navbar.css';
import data from './settings.json'
import icon from './menu-icon.svg';
import close from './menu-x.svg';
import {Credentials} from '../login/fn_login';
import CONFIG from '../../config.json';

export default function Navbar({opacity=false}){
    const navigate = useNavigate();
    const [clicked,setClicked] = useState(false);

    // Navbar Settings
    const redirect = (path) => {
        setClicked(false);
        navigate(path);
    }

    const handleLogout = () => {Credentials.destroy();}

    // NAVBAR
    return (
        <div className={opacity ? 'navbar opacity' : 'navbar'}>
            <div className='nav-content'>
                <div className='logo' onClick={() => redirect('/')}>
                    <img src='./logo192.png' alt={data.imgseo.alt} title={data.imgseo.title} /> 
                    <div className='nav-title'>{data.title}</div>
                </div>

                <div className='nav-button' alt={data.imgseo.alt} title={data.imgseo.title} onClick={() => setClicked(a => !a)}>
                    <img src={clicked ? close : icon} alt={data.imgseo.alt} title={data.imgseo.title} />
                </div>
                

                <ul className={clicked ? 'menu clicked' : 'menu'}>
                    {data.links.map((a, i) => (
                        <li className='nav-link' key={i} onClick={() => redirect(a.to) }>
                            <Link to={a.to}>{a.name}</Link>
                        </li>
                    ))}
                        {Credentials.token() === null && <li className='nav-login-link' onClick={() => redirect("/login") }>LOGIN</li>}
                        {/*login && <li className='login-link' onClick={() => handleLogout()}>LOGOUT</li>*/}
                        {Credentials.token() !== null && <li className='nav-login-link' onClick={() => redirect("/account")}>KONTO</li>}
                    </ul>
            </div>
        </div>
    );
}


import React,{useEffect,useState} from 'react';
import {useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './fn_login.css';
import {log_credentials, log_action} from './fn_log'
import imageLoader from './loader.gif';

// ================= LOOGIC =================
// FUNCTIONS
const apiLogin = async (apiUrl, webId, jsonItem) => {
    const b = JSON.stringify(jsonItem);
    try{
        const response = await fetch(apiUrl + "/account/" + webId + "/login", {method:'post', body:b,  headers:{"Content-Type":"application/json"}});
        if(response.ok)
        {
            var data = await response.json();
            new Credentials(data, apiUrl);
            if(!Credentials.isValid()) return {ok:false,text:'Response ok but could not parse user object'};
            // log here
            log_credentials(webId, apiUrl);
            return { ok:true, text:data.text}
        }
        else return {ok:false, text:await response.text()}
    }
    catch(e) {
        return {ok:false, text:e}
    }
}

const apiPwdRecovery = async (apiUrl, webId, email) => {
    const request = await fetch(apiUrl +`/account/${webId}/recovery/${email}`,{method:'get'} );
    return request.ok ? {ok:true, text:"Account info sent"} : {ok:false, text:await request.text()};
}

const apiRegister = async (apiUrl, webId, jsonItem) => {
    const body = JSON.stringify(jsonItem);
    const request = await fetch(apiUrl + `/account/${webId}/register`, {method:'post', body:body, headers:{"Content-Type":"application/json"}});
    return request.ok ? {ok:true, text:"Check your email and verify account"} : {ok:false, text:await request.text()};
}

const apiCreate = async (apiUrl, webId, ticket) => {
    const req = await fetch(apiUrl + `/account/${webId}/create/${ticket}`, {method:'get', headers:{"Content-Type":"application/json"}});
    if(req.ok)
    {
        var data = await req.json();
        new Credentials(data, apiUrl);
        log_action(webId, apiUrl, 'account-create',`${Credentials.user()}`);
    }
    return req.ok ? {ok:true, text:'Account Created'} : {ok:false, text:await req.text()};
}

const apiSave = async (apiUrl, webId, jsonItem) => {
    const body = new FormData();
    body.append('token', Credentials.token());
    body.append('account', JSON.stringify(jsonItem));
    const req = await fetch(apiUrl + `/account/${webId}/save`, {method:'post', body:body});
    return req.ok ? {ok:true, text:'Account updated'} : {ok:false, text:await req.text()};
}

const apiDelete = async (apiUrl, webId) => {
    const req = await fetch(apiUrl + `/account/${webId}/delete/${Credentials.token()}/${Credentials.id()}`, {method:'get', headers:{"Content-Type":"application/json"}});
    log_action(webId, apiUrl, 'delete-account',`${Credentials.user()}`);
    if(req.ok)
        Credentials.destroy();
    return req.ok ? {ok:true, text:'Your account and all user data has been deleted'} : {ok:false, text:await req.text()};
} 

export const apiLogout = async (apiUrl, webId) => {
    const token = Credentials.token();
    Credentials.destroy();
    if(token !== null)
    {
        const request = await fetch(apiUrl + `/account/${webId}/logout/${token}`, {method:'get'});
        return request.ok ? {ok:true, text:"Your are logged out"} : {ok:false, text:'Faild to destroy token at server'}
    }
    return {ok:true,text:'No login token found on local session'}
}

export async function autoLogin(){
    // try autologin
    if(Credentials.isValid())
    {
        try{
            const api = Credentials.api();
            const req = await fetch(api + `/account/${Credentials.web()}/refresh/${Credentials.token()}`,{method:'get', headers:{"Content-Type":"application/json"}});
            const data = await req.json();
            new Credentials(data, api);
            return Credentials.isValid();
        }
        catch
        {
            console.log("Token is exipred");
            Credentials.destroy();
        }
    }
    return false;
}

// Credentials 
export class Credentials {
    static keys = ['web','id','user','role','token','api','created'];
    constructor(json = null, api = null) {
        if(json !== null){
            Object.keys(json).forEach(key => {
                if(Credentials.keys.includes(key.toLowerCase()))
                    localStorage.setItem(key.toLowerCase(), json[key]);
            });
            localStorage.setItem('created', new Date().getTime());
        }
        if(api !== null)
            localStorage.setItem('api', api);
    }
    
    // credentials        
    static api() {return localStorage.getItem('api',null);}
    static web() { return localStorage.getItem('web',null);}
    static id() {return localStorage.getItem('id',null);}
    static user() {return localStorage.getItem('user',null);}
    static role() {return localStorage.getItem('role',null);}
    static token() {return localStorage.getItem('token',null);}
    
    static needRefresh() {  
        const addHours = (h) => h*3600;
        var created = localStorage.getItem('created', null);
        if(created !== null)
        {
            if(!isNaN(created))
            {
                if((parseInt(created) + addHours(5)) > (new Date()).getTime())
                    return false;
            }
        }
        return true;
    }

    static isValid() {
        var valid = true;
        Credentials.keys.forEach(key => {if(localStorage.getItem(key,null) === null) valid = false;});
        return valid;
    }

    static destroy() {
        Credentials.keys.forEach(key => localStorage.removeItem(key));
    }
}

// HOOKS
export const useAutoLogin = async () => {
    const [isLoggedIn,setIsLoggedIn] = useState(null);
    const [runOnce,setRunOnce] = useState(false);

    useEffect(() => setRunOnce(true) ,[]);
    useEffect(() => {
        if(runOnce){
            if(Credentials.isValid())
                setIsLoggedIn(Credentials.needRefresh() ? autoLogin() : true);
            setRunOnce(false);
        }
    },[runOnce]);
    return isLoggedIn;
}

// Components
export function LoginDialogue({apiUrl, webId, redirect}){
    const [loading,setLoading] = useState(false);
    const [viewId,setViewId] = useState(0);
    const [outcome,setOutcome] = useState({});
    const navigate = useNavigate();
   
    const isOutcome = Object.keys(outcome).length > 0;

    useEffect(() => {
        if(window.location.href.indexOf('?ticket=')> 0)
            setViewId(3);
        
    },[]);

    const verifyInput = (email = null, pwd = null) => {
        if(email !== null && email !== undefined)
        {
            const att = email.indexOf('@');
            if(email.trim().length === 0 || att === email.length-1 || att === 0 || att <= 0) return false;
        }
        if(pwd !== null && pwd !== undefined)
            if(pwd.trim().length === 0 || pwd.indexOf(' ') >= 0 || pwd.includes('\\') || pwd.includes("'") || pwd.includes('`')) return false;
        return true;
    }

    // VIEWS
    const ViewLogin = () => {
        const [user,setUser] = useState('');
        const [pwd,setPwd] = useState('');

        const login_click = async () => {
            setLoading(true);
            setOutcome({});
            const response = await apiLogin(apiUrl, webId, {user:user, password:pwd});
            if(response.ok)
            {
                setLoading(false);
                navigate(redirect);
            }
            else
            {
                setLoading(false);
                setOutcome(response);
            }
        }

        return (
        <div className='fn_login'>
            <span className='header'>Login</span>
            <div className='info centrify'>
                {loading && <div className='loader centrify'><img src={imageLoader} /> Hold on...</div>}
                {!loading && <div className={isOutcome ? (outcome.ok ? null : 'error'):'hide' }>{isOutcome ? outcome.text : ''}</div>}
            </div>

            <form>
            <input type='text' placeholder='Email' value={user} onChange={(e) => setUser(e.target.value)}  />
            <div className='space' />

            <div className='password'>
                <input type='password' placeholder='Password' value={pwd} onChange={(e) => setPwd(e.target.value)} />
                <button className='button-link' onClick={() => {setOutcome({});setViewId(1);}}>Forgot password?</button>
            </div>
            </form>
            <div className='space' />

            <button onClick={() => login_click()} className='button' disabled={!verifyInput(user,pwd)}>Login</button>

            <div className='space' />

            <button className='button-link' onClick={() => {setOutcome({});setViewId(2);}}>Register</button>

        </div>);
    }

    const ViewPasswordRecovery = () => {
        const [email,setEmail] = useState('');

        const recovery_click = async () => {
            setLoading(true);
            const resp = await apiPwdRecovery(apiUrl, webId, email);
            setOutcome(resp);
            setEmail('');
            setLoading(false);
        }

        return (<div className='fn_login'>
            <span className='header'>Password Recovery</span>
            <div className='info centrify'>
                {loading && <div className='loader centrify'><img src={imageLoader} /> Hold on...</div>}
                {!loading && <div className={isOutcome ? (outcome.ok ? null:'error') : null}>{outcome.text || ''}</div>}
            </div>
            <input type='text' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <div className='space' />
            <button className='button' disabled={!verifyInput(email)} onClick={() => recovery_click()}>Help me</button>
            <div className='space' />
            <button className='button-link' onClick={() => {setOutcome({});setViewId(0);}}>Login</button>

        </div>)
    }

    const ViewRegister = () => {
        const [email,setEmail] = useState('');
        const [pwd,setPwd] = useState('');

        const register_click = async () => {
            setLoading(true);
            const link = window.location.pathname;
            const resp = await apiRegister(apiUrl, webId, {user:email, password:pwd, link:link});
            setOutcome(resp);
            setLoading(false);
        }

        return (
            <div className='fn_login'>
                <span className='header'>Register</span>
                <div className='info centrify'>
                    {loading && <div className='loader centrify'><img src={imageLoader} /> Hold on...</div>}
                    {!loading && <div className={isOutcome ? (outcome.ok ? null : 'error') : null}>{outcome.text || ''}</div>}
                </div>
                <input type='text' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <div className='space' />
                <input type='text' placeholder='Password' value={pwd} onChange={(e) => setPwd(e.target.value)} />
                <div className='space' />
                <button className='button' disabled={!verifyInput(email,pwd)} onClick={() => register_click()}>Register</button>
                <div className='space' />
                <button className='button-link' onClick={() => {setOutcome({});setViewId(0);}}>Login</button>
            </div>
        );
    }

    const ViewVerifyAccount  = () => {
        const [params] = useSearchParams();
        const [firstRender,setFirstRender] = useState(false);
        // cant use global object since cirular reload
        const [load,setLoad] = useState(false); 
        const [info,setInfo] = useState({});

        useEffect(() => setFirstRender(true),[]);
        useEffect(() => {
            if(firstRender)
                verifyAccount();
        },[firstRender]);

        const verifyAccount = async () => {
            setFirstRender(false);
            const ticket = params.get('ticket');
            setLoad(true);
            const resp = await apiCreate(apiUrl, webId, ticket);
            setInfo(resp);
            setLoad(false);
        }

        return (
            <div className='fn_login'>
                <span className='header'>Verifying Account</span>
                <div className='info centrify'>
                    {load && <div className='loader centrify'><img src={imageLoader} /> Hold on...</div>}
                    {!load && <div className={Object.keys(info).length > 0 ? (info.ok ? null : 'error') : null}>{info.text || ''}</div>}
                </div>
                <div className='space' />
                <button className='button' onClick={() => {setOutcome({});navigate(redirect)}} disabled={load}>OK</button>
            </div>
        );
    }

    return (
        <>
            {viewId === 0 && <ViewLogin />}
            {viewId === 1 && <ViewPasswordRecovery />}
            {viewId === 2 && <ViewRegister />}
            {viewId === 3 && <ViewVerifyAccount />}
        </>
    )
}

export function ManageAccount({apiUrl, webId, redirect}){
    const [outcome,setOutcome] = useState({});
    const [account,setAccount] = useState({});
    const [loading,setLoading] = useState(false);
    const [pwd,setPwd] = useState('');
    const navigate = useNavigate();

    useEffect(() => setAccountInfo(),[]);

    const setAccountInfo = () => {setAccount({user:Credentials.user(), id:Credentials.id(), role:Credentials.role()})}

    const handleLogout = async () => {
        setLoading(true);
        const req = await apiLogout(apiUrl, webId);
        setLoading(false);
        navigate(redirect)
    }

    const handlePwdUpdate = async () => {
        setLoading(true);
        const account_copy = {...account};
        account_copy.password = pwd
        const req = await apiSave(apiUrl, webId, account_copy);
        setOutcome(req);
        setPwd('');
        setLoading(false);
    }

    const handleDelete = async () => {
        setLoading(true);
        const req = await apiDelete(apiUrl, webId);
        setOutcome(req);
        setAccountInfo();
        setLoading(false);
    }

    return (
    <div className='fn_login'>
        <span className='header'>Your Account</span>
        <div className='info centrify'>
            {loading && <div className='loader centrify'><img src={imageLoader} /> Hold on...</div>}
            {!loading && <div className={Object.keys(outcome).length > 0 ? (outcome.ok ? null : 'error') : 'hide'}>{outcome.text || ''}</div>}
        </div>
        
        <div className='account-id'>
            {account.user || '(not logged in)'}
        </div>

        <div className='space' />

        <button className='button-link' onClick={() => handleLogout() } disabled={!account.user}>Logout</button>

        <div className='big-space' />

        <div className='change-pwd'>
            <input type='text' placeholder='New password' disabled={!account.user} value={pwd} onChange={(e)=>setPwd(e.target.value)} />
            <button onClick={() => handlePwdUpdate()} disabled={!account.user || pwd.length === 0}>Change</button>
        </div>

        <div className='big-space' />

        <button disabled={!account.user || account.role === 'admin'} onClick={() => handleDelete()}>Delete Account</button>
        
    </div>)
}
import React, { useEffect, useState } from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import './App.css';
import CONFIG from './config.json';
// resources
import Editor from './comp/editor/editor';
import Method from './comp/method/method';
import Home from './comp/home/home';
import Support from './comp/support/support';
import {useAutoLogin} from './comp/login/fn_login';
import {log_location} from './comp/login/fn_log';
import Login from './comp/login/login';
import Exercise from './comp/exercise/exercise';
import Yoga from './comp/yoga2/yoga';



function App() {
  const isLoggedIn = useAutoLogin();

  const [firstRender,setFirstRender] = useState(false);
    useEffect(() => setFirstRender(true),[]);
    useEffect(() => {
      if(firstRender){
        log_location(CONFIG.web, CONFIG.api);
        setFirstRender(false);
      }
  },[firstRender]);

   

  return (
    <BrowserRouter>
      <div className='app'>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/yoga2" element={<Yoga />} />
        <Route path="/method" element={<Method />} />
        <Route path="/method-edit" element={<Editor title={'HANDLE METHOD'} apiUrl={CONFIG.api} dataDir={CONFIG.dbDir} fileDir={CONFIG.fileDir} web={CONFIG.web} name={'method'} />} />
        <Route path="/exercise" element={<Exercise web={CONFIG.web} apiUrl={CONFIG.api} dbDir={CONFIG.dbDir} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/login" element={<Login page={'login'} />} />
        <Route path="/account" element={<Login page={'account'} />} />
        </Routes>
        </div>
    </BrowserRouter>
  );
}

export default App;
